import * as constants from '../../../consts'

import { Link, graphql, useStaticQuery, navigate } from 'gatsby'
import React, { useRef, useState } from 'react'

import { ReactComponent as CloseIcon } from '../../../../node_modules/feather-icons/dist/icons/x.svg'
import { ReactComponent as DownIcon } from '../../../../node_modules/feather-icons/dist/icons/chevron-down.svg'
import { ReactComponent as MenuIcon } from '../../../../node_modules/feather-icons/dist/icons/menu.svg'
import getCategoryList from '../../../helpers/getCategoryList'
import logo from '../../../images/sinaran-logo-2.png'
/*eslint-disable */
import { motion } from 'framer-motion'
import styled from 'styled-components'
import tw from 'twin.macro'
import useAnimatedNavToggler from '../../../helpers/useAnimatedNavToggler.js'
import useDetectOutsideClick from '../../../helpers/useDetectOutsideClick.js'
import useWindowDimensions from '../../../helpers/useWindowDimensions'

const LargeHeader = tw.header`w-full lg:py-8`

const InternalHeader = tw.div`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`

export const NavLinks = tw.div`flex`

/* focus: stands for "on hover or focus"
 * focus:bg-green-700 will apply the bg-green-700 class on hover or focus
 */

const BaseLink = tw.a`
  text-lg my-2 lg:text-base lg:mx-6 lg:my-0
  font-semibold transition duration-300
  pb-1 border-b-2 border-transparent focus:text-green-500
  text-gray-800  cursor-pointer uppercase
`

export const NavLink = tw(BaseLink)`
  hover:border-green-500 focus:text-green-500 hover:text-green-500
`

const LinkWithDropdown = styled.div(({ shouldShow }) => [
  tw`relative z-60`,
  // Ternary
  shouldShow ? tw`lg:block hidden` : tw`lg:hidden hidden`,
])

const DropdownContainer = tw.div`absolute z-10 -ml-4 mt-8 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 shadow-lg rounded-lg`
const DropdownInnerContainer = tw.div`ring-1 ring-white ring-opacity-5 overflow-hidden`
const DropdownInnerInnerContainer = tw.div`relative grid grid-cols-3 gap-y-0 gap-x-4 bg-gray-50 px-5 py-6 sm:gap-8 sm:p-8`
const DropdownLink = tw.a`-m-3 p-3 flex items-start hover:bg-green-500 bg-green-50`
const DropdownTitle = tw.p`text-base font-semibold text-gray-700 group-hover:text-white group-hover:font-bold`
const DropdownLabelLink = tw.a`-m-3 p-1 flex items-start rounded-lg col-span-3`
const DropdownLabelTitle = tw.p`tracking-wider text-sm text-gray-500 uppercase`

const MobileSubLinksContainer = tw.div`block lg:hidden w-full text-left mx-4 my-1`
const MobileLinks = tw.a``

const MobileLinkTitle = tw(DropdownTitle)`
  p-2 group-hover:text-white
`
export const ActiveNavLink = tw.a`
  text-lg my-2 lg:text-base lg:mx-6 lg:my-0
  font-bold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent border-green-500
 text-green-500 cursor-pointer cursor-pointer uppercase
`

export const LogoLink = styled((props) => <BaseLink {...props} />)`
  ${tw`flex items-center font-bold border-b-0 text-lg! ml-0! text-green-500`};

  img {
    ${tw`w-14 h-12 mr-3`}
  }
`

export const MobileNavLinksContainer = tw.nav`p-8 flex flex-1 items-center justify-between z-50`
export const NavToggle = tw.button`
  lg:hidden z-50 focus:outline-none focus:text-green-500 transition duration-300
`
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-50 fixed top-0 inset-x-0 p-8 text-center text-gray-200 bg-gray-50 h-screen overflow-y-scroll`}
  ${NavLinks} {
    ${tw`flex flex-col items-start mt-12`}
  }
`)

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`

export const HeaderDark = ({
  logoLink,
  links,
  className,
  collapseBreakpointClass = 'lg',
  activeLink,
}) => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler()
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  let { width } = useWindowDimensions() || { width: 2000 }
  const [showSubLink, setShowSubLink] = useState(true)

  const onClick = () => {
    if (width >= constants.lgWidth) setIsActive(!isActive)
    else navigate('/category/all')
  }

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allSitePage(sort: { fields: internalComponentName }) {
        edges {
          node {
            path
          }
        }
      }
    }
  `)

  let tempListOfCategories = getCategoryList(data.allSitePage)

  if (width <= constants.lgWidth) {
    tempListOfCategories = tempListOfCategories.filter(
      (o) => o.node.internalComponentName != 'All'
    )
  }

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass]
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this InternalHeader to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const dropdownLinks = (
    <LinkWithDropdown shouldShow={isActive} ref={dropdownRef}>
      <DropdownContainer>
        <DropdownInnerContainer>
          <DropdownInnerInnerContainer>
            <DropdownLabelLink>
              <DropdownLabelTitle>Categories</DropdownLabelTitle>
            </DropdownLabelLink>

            {tempListOfCategories.map(({ node }) => {
              return (
                <DropdownLink
                  className={'group'}
                  as={Link}
                  key={node.internalComponentName}
                  to={node.path}
                >
                  <DropdownTitle>{node.internalComponentName}</DropdownTitle>
                </DropdownLink>
              )
            })}
          </DropdownInnerInnerContainer>
        </DropdownInnerContainer>
      </DropdownContainer>
    </LinkWithDropdown>
  )

  const mobileLinks = (
    <MobileSubLinksContainer>
      {tempListOfCategories.map(({ node }) => {
        return (
          <div onClick={() => navigate(node.path)}
            key={node.internalComponentName}
            className={'group hover:bg-green-500 cursor-pointer'}
          >
            <MobileLinkTitle>{node.internalComponentName}</MobileLinkTitle>
          </div>
        )
      })}
    </MobileSubLinksContainer>
  )

  const defaultLinks = [
    <NavLinks key={1}>
      {activeLink.toLowerCase() === 'home' ||
      activeLink.toLowerCase() === '#' ||
      activeLink.toLowerCase() === '' ? (
        <ActiveNavLink as={Link} to="/#">
          Home
        </ActiveNavLink>
      ) : (
        <NavLink as={Link} to="/#">
          Home
        </NavLink>
      )}
      {activeLink.toLowerCase() === 'about' ? (
        <ActiveNavLink as={Link} to="/about">
          About Us
        </ActiveNavLink>
      ) : (
        <NavLink as={Link} to="/about">
          About Us
        </NavLink>
      )}
      {activeLink.toLowerCase() === 'products' ||
      activeLink.toLowerCase().indexOf('category') !== -1 ||
      activeLink.toLowerCase().indexOf('product') !== -1 ? (
        <>
          <ActiveNavLink onClick={onClick}>
            <div tw={'inline-flex text-base '}>
              Products{' '}
              {width >= constants.lgWidth && <DownIcon tw={'ml-2 h-5 w-5'} />}
            </div>
          </ActiveNavLink>
          {mobileLinks}
          {width >= constants.lgWidth && dropdownLinks}
        </>
      ) : (
        <>
          <NavLink onClick={onClick}>
            <div tw={'inline-flex text-base '}>
              Products{' '}
              {width >= constants.lgWidth && <DownIcon tw={'ml-2 h-5 w-5'} />}
            </div>
          </NavLink>
          {mobileLinks}
          {width >= constants.lgWidth && dropdownLinks}
        </>
      )}
      {activeLink.toLowerCase() === 'distributor' ? (
        <ActiveNavLink as={Link} to="/distributor">
          Be our Distributor
        </ActiveNavLink>
      ) : (
        <NavLink onClick={toggleNavbar} as={Link} to="/distributor">
          Be our Distributor
        </NavLink>
      )}
      {activeLink.toLowerCase() === 'contact' ? (
        <ActiveNavLink as={Link} to="/contact">
          Contact Us
        </ActiveNavLink>
      ) : (
        <NavLink onClick={toggleNavbar} as={Link} to="/contact">
          Contact Us
        </NavLink>
      )}
    </NavLinks>,
  ]

  const defaultLogoLink = (
    <LogoLink as={Link} to="/">
      <img src={logo} alt="logo" />
      Sinaran Rempah Asli
    </LogoLink>
  )

  logoLink = logoLink || defaultLogoLink
  links = links || defaultLinks

  return (
    <LargeHeader className={className}>
      <InternalHeader className={className || 'header-light'}>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {links}
        </DesktopNavLinks>

        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          {logoLink}
          <MobileNavLinks
            initial={{ x: '150%', display: 'none' }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            <CloseIcon
              onClick={toggleNavbar}
              tw="absolute top-11 right-8 text-gray-900 w-6 h-6"
            />
            {links}
          </MobileNavLinks>

          <NavToggle
            aria-label="Menu"
            onClick={toggleNavbar}
            className={showNavLinks ? 'open' : 'closed'}
          >
            {showNavLinks ? (
              <></>
            ) : (
              // <div> close </div>
              <MenuIcon tw="text-gray-900 w-6 h-6" />
              // <div> opens </div>
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </InternalHeader>
    </LargeHeader>
  )
}
/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
}
