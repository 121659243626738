export default function getCategoryList(data) {
  var tempListOfCategories = []

  data.edges.forEach((o) => {
    if (o.node.path.indexOf('category') !== -1 && !/\d/.test(o.node.path)) {
      o.node.internalComponentName = o.node.path.substring(
        nth_occurrence(o.node.path, '/', 2) + 1,
        o.node.path.length
      )

      var str = o.node.internalComponentName
      var splitStr = str.toLowerCase().split(' ')
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      // Directly return the joined string
      o.node.internalComponentName = splitStr.join(' ')

      if (o.node.internalComponentName !== 'All' && o.node.internalComponentName !== 'Rempah-rempah') {
        tempListOfCategories.push(o)
      } else {
        tempListOfCategories.unshift(o)
      }
    }
  })

  return tempListOfCategories
}

export function nth_occurrence(string, char, nth) {
  var first_index = string.indexOf(char)
  var length_up_to_first_index = first_index + 1

  if (nth == 1) {
    return first_index
  } else {
    var string_after_first_occurrence = string.slice(length_up_to_first_index)
    var next_occurrence = nth_occurrence(
      string_after_first_occurrence,
      char,
      nth - 1
    )

    if (next_occurrence === -1) {
      return -1
    } else {
      return length_up_to_first_index + next_occurrence
    }
  }
}
