import PropTypes from 'prop-types'
import React from 'react'
// import Footer from "./footer";
import tw from 'twin.macro'
// import {Hero} from "./components/hero/TwoColumnWithVideo";
import { HeaderDark } from './components/headers/dark.js'
import { Footer } from './components/footers/FiveColumnDark'
import { globalHistory } from '@reach/router'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'

const MainContainer = tw.div`min-h-screen overflow-hidden`

function Layout({ children, Hero, bgColor }) {
  const currentPath = globalHistory.location.pathname.replace(/[\W_]/g, '')
  return (
    <>
      <Helmet>
        <script src={withPrefix('index.js')} type="text/javascript" />
      </Helmet>

      <MainContainer>
        <HeaderDark className={bgColor} activeLink={currentPath} />
        {Hero && Hero}
        {/*<main className="flex-1 w-full px-4 py-8 mx-auto md:px-8 md:py-16">*/}
        <main className="flex-1 w-full">{children}</main>

        <Footer />
      </MainContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
